

<template >
    
    <div class="cell medium-10 small-12" style="padding:2px">
       
        <template v-if="loadedcomps">
            <nav>
                <div class="grid-x" style="margin-top:5px">

                    <div class="cell text-center">
                        <router-link :to="{name:'events', params: {eventparams: selectedcomps.join('/')}}"><button v-if="selectedcomps.length > 0" class="button qheadercolour qborderlink " >{{ langConv('Create a coupon with your selections')}} - <span class="badge alert">{{selectedcomps.length}}</span></button></router-link>
                    </div>

                </div>
            </nav>
            <div>         
           
                <tabs @changed="tabChanged">
                    <tab :title="langConv('Match Odds')"  :description="langConv('Match Odds gives bettor the chance to bet on which team they think will win a given match')" img="" :tabtype="$tabtype.textonly" :isactive="tabid==0" class="qborderfontcolour">
                        <template >
                            
                            <div class="content" v-if="unique(competitionsforsport,'Co', 'ortpo', '1').length == 0">
                                {{ langConv('No current matches for this sport') }}
                            </div>
                            <div v-for="(row,i) in unique(competitionsforsport,'Co', 'ortpo', '1')" :key="i">
                          
                            <template v-if="row.Co == opencountry" >
                                <details  open>
                                    <summary class="qmediumfont"><img class="qflagsmall" style="margin-right:4px" :src="'/assets/images/flags/svg/' + row.Co + '.svg'" :alt="row.Co"/><span style="margin-left:8px">{{ row.Co }}</span></summary>
                                    <div class="grid-x grid-padding-x  qmediumfont qborderfontcolour" style="padding:5px">
                                        <template v-for="(row2,i2) in competitionsforsport">
                                            <template  v-if="row2.Co == row.Co && row2.ortpo== 1">
                                                <div class="cell small-12 medium-6 large-4" :key="i2" ><input class="qpointer" :id="row2.Co + i2" type="checkbox" :value="row2.CoID + '-' + row2.ortpo + '-' + btid" v-model="selectedcomps" ><label style="margin-bottom:-5px;overflow: hidden;text-overflow: ellipsis;" class="qsmallfontplus1"  :for="row2.Co + i2">{{row2.Comp}}</label> </div>
                                            </template>
                                        </template>
                                    </div>
                                </details>
                            </template>
                            <template v-else>
                                
                                <details >
                                  
                                    <summary class="qmediumfont qmarginbelow" ><img class="qflagsmall" :src="'/assets/images/flags/svg/' + row.Co + '.svg'" :alt="row.Co"/><span style="margin-left:8px">{{ row.Co }}</span></summary>
                                    <div class="grid-x " style="padding:3px">
                                        <template v-for="(row2,i2) in competitionsforsport">
                                            <template v-if="row2.Co == row.Co && row2.ortpo== 1">
                                                <div class="cell small-12 medium-6 large-4" :key="i2" ><input class="qpointer" :id="row2.Co + i2" type="checkbox" :value="row2.CoID + '-' + row2.ortpo + '-' + btid" v-model="selectedcomps"><label style="margin-bottom:-5px;overflow: hidden;text-overflow: ellipsis;" class="qsmallfontplus1"  :for="row2.Co + i2">{{row2.Comp}}</label></div>
                                            </template>
                                        </template>
                                    </div>
                                </details>
                            </template>

                            </div>
                        </template> 
                       
                    </tab>
                    <tab :title="langConv('Outright Winner Odds')" :description="langConv('Outright Winner Odds gives the better who will win the league / tournament')" img="" :tabtype="$tabtype.textonly" :isactive="tabid==1">
                        <template >

                            <div class="content" v-if="unique(competitionsforsport,'Co', 'ortpo', '0').length == 0">
                                No current outrights for this sport
                            </div>
                            <div v-for="(row,i) in unique(competitionsforsport,'Co', 'ortpo', '0')" :key="i">
                            <details>
                                <summary class="qmediumfont qmarginbelow"><img class="qflagsmall" :src="'/assets/images/flags/svg/' + row.Co + '.svg'"/><span style="margin-left:8px">{{ row.Co }}</span></summary>
                                <div class="grid-x grid-padding-x small-up-2 medium-up-3 qsmallfontplus1 " style="padding:5px">
                                    <template v-for="(row2,i2) in competitionsforsport" >

                                        <div class="cell" :key="i2" v-if="row2.Co == row.Co && row2.ortpo == 0"><input class="qpointer" style="z-index:1" :id="row2.Co + row2.i" type="checkbox" :value="row2.CoID + '-' + row2.ortpo + '-' + btid" v-model="selectedcomps">&nbsp;{{row2.Comp}}</div>

                                    </template>
                                </div>
                            </details>
                            </div>
                        </template>
                    </tab>

                </tabs>
            </div>
        
        </template>
</div>
</template>


<script>
 
   // adapted from https://codepen.io/frogmcw/pen/deqRwa after failing to get the foundation accordian and Vue to work nicely
import axios from 'axios'
import tab from '../components/modified3rdpartycomponents/tab.vue'
import tabs from '../components/modified3rdpartycomponents/tabs.vue'

    export default {

        name: "sport",
        data: function () {
            return {
                competitionsforsport: [],
                loadedcomps: false,
                selectedcomps: [],
                tpo: 1,
                btid: 1,
                sportname: this.sport,
                tabid: 0
            };
        },
        components: {
            tab,
            tabs
        },

        props: {

            sport: String,
            opencountry: String
        },

        mounted() {
            this.competitionsforSportTable();
        },

        beforeRouteUpdate(to, from, next) {

            this.sportname = to.params.sport;
            this.opencounty = to.params.opencounty;
            this.competitionsforSportTable();
            next();
            
        },

        methods: {

            tabChanged: function(tab) {
              
            if (tab != this.tabid) {
                this.tabid = tab;
            }    
        },

            //competitions have 
            createCoupon: function () {
                
                var URL = '/competition/' + this.selectedcomps.join('/');
                this.$router.push(URL);
                
            },
           
            //get data for odds table
            competitionsforSportTable: function () {

              
                axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/CompetitionsbySport", {

                    params: {
                        noitems: 999,
                        country: this.$store.state.countryname,
                        sport: this.sportname
                    }

                }).then(response => {

                    

                    this.competitionsforsport = JSON.parse(response.data)[0].BO; 

                    this.loadedcomps = true;});

      

            },

        }

    }
</script>

<style scoped>

    details {
        width: 100%;
        /* min-height: 30px; */
        max-width: 1024px;
        padding: 15px 25px 15px 15px;
        margin: 0 auto;
        position: relative;
        font-size: 16px;
        border: 1px solid rgba(0,0,0,.1);
        border-radius: 10px;
        box-sizing: border-box;
        transition: all .3s;
    }

        details + details {
            margin-top: 20px;
        }

        details[open] {
            min-height: 30px;
            /* background-color: #f6f7f8; */
            box-shadow: 2px 2px 20px rgba(0,0,0,.2);
            z-index:1 !important
     
        }

        /* details div {
 
        } */

    summary {
        font-weight: 500;
        cursor: pointer;
    }

        summary:focus {
            outline: none;
        }

        summary::-webkit-details-marker {
            display: none
        }

        summary::after {
            padding: 20px;
            position: absolute;
            top: 50%;
            right: 0;
            color: darkslategray;
            font-size: 12px;
            font-weight:bolder;
            font-style: normal;
            font-variant-caps: normal;
            font-variant-ligatures: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            content: ">";
            transform: translateY(-50%);
            transition: .3s ease;
        }

    details[open] summary::after {
        content: "x";
        font-size: 12px;
        top: 0;
        transform: translateY(0);
        transition: .3s ease;
    }

    details[open] summary:hover::after {
        animation: pulse 1s ease;
    }

    @keyframes pulse {
        25% {
            transform: scale(1.1);
        }

        50% {
            transform: scale(1);
        }

        75% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }



</style>





